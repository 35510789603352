<template>
  <div class="footerComponent" >
    <footer>
      <div class="footer-container">
        <div class="col-md-4 col-12 footer-right-border footer-col">
          <p class="text-center">
            <strong style="font-family: Magneto, sans-serif; font-size: 30px;"> Geegz </strong>
          </p>
          <p class="text-center">
            18 passage du chantier <br>
            75012 Paris, France
          </p>
          <p class="text-center">
            06.64.67.26.02 <br>
            contact@geegz.fr
          </p>
        </div>
        <div class="col-md-4 col-12 footer-right-border footer-col">
          <p class="text-center">
            <strong style="font-size: 22px;"> Horaires </strong>
          </p>
          <p class="text-center">
            Du lundi au vendredi <br>de 9h00 à 18h00
          </p>
          <b-button to='/contact' class="p-3" variant="outline-light">
            Nous contacter directement
          </b-button>
        </div>
        <div class="col-md-4 col-12  footer-col">
          <ul class="menu-footer">
            <li> <router-link to='/'> Accueil </router-link> </li>
            <li> <router-link to='/contact'> Nous contacter </router-link> </li>
            <li> <router-link to='/mentions-legales'> Mentions légales </router-link> </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footerComponent',
}
</script>

<style>
/* --------------------- FOOTER ------------------*/

footer{
  color: white;
}

footer .footer-container {
  background-color: black;
  padding: 43px 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

footer .footer-container .row {
  width: 100%;
}

.footer-right-border {
  border-bottom: 1px solid hsla(0,0%,100%,.5) !important;
}

.footer-col {
  height: auto !important;
  padding: 40px 10px;
  margin: 0;
  width: 100%;
}

footer .footer-bottom * {
    padding: 0;
}

footer .footer-container-background-top {
    height: 1000px;
    position: absolute;
    width: 100%;
    background-color: #fff !important;
    z-index: 1;
    margin-top: -1500px;
}

footer .menu-footer {
    list-style-type: none;
    padding-left: 0;
    font-size: 18px;
    text-transform: uppercase;
    display: grid;
    height: 100%;
}

footer .menu-footer a {
  color: white;
  text-decoration: none;
}

footer .menu-footer a:hover {
  font-weight: bold;
}

@media (min-width: 768px) {
  .footer-right-border {
    border-bottom: none !important;
    border-right: 1px solid hsla(0,0%,100%,.5);
  }
}

</style>
